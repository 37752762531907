var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getDataLoading)?_c('div',{staticClass:"floating-loading"},[_c('div',{staticClass:"d-flex",staticStyle:{"border":"1px solid #e8e9eb","width":"120px","padding":"10px","border-radius":"10px"}},[_c('a-spin',[_c('a-icon',{staticClass:"mr-3 mb-0",staticStyle:{"font-size":"20px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1),_vm._v(" Loading... ")],1)]):_vm._e(),_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body w-100"},[_c('div',{staticClass:"mb-4 h4 font-weight-bold text-color-green"},[_vm._v(" Formulir Registrasi Kunjungan ")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Nama Lengkap")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: this.name,
                rules: [
                  {
                    required: true,
                    message: 'Masukkan Nama Lengkap Pasien!',
                  },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                initialValue: this.name,\n                rules: [\n                  {\n                    required: true,\n                    message: 'Masukkan Nama Lengkap Pasien!',\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","size":"default","placeholder":"Masukkan nama lengkap"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Tanggal Lahir")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'birthdate',
              {
                initialValue: this.birthdate,
                rules: [
                  {
                    required: true,
                    message: 'Masukkan tanggal lahir Pasien!',
                  },
                ],
              },
            ]),expression:"[\n              'birthdate',\n              {\n                initialValue: this.birthdate,\n                rules: [\n                  {\n                    required: true,\n                    message: 'Masukkan tanggal lahir Pasien!',\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","size":"default","placeholder":"Masukkan tanggal lahir"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v(" Jenis Kunjungan"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'visit_type',
              {
                initialValue: 'Rawat Jalan',
                rules: [
                  {
                    required: true,
                    message: 'Masukkan Jenis Kunjungan Pasien!',
                  },
                ],
              },
            ]),expression:"[\n              'visit_type',\n              {\n                initialValue: 'Rawat Jalan',\n                rules: [\n                  {\n                    required: true,\n                    message: 'Masukkan Jenis Kunjungan Pasien!',\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","size":"default","placeholder":"Masukkan jenis kunjungan"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v(" Layanan"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'layanan',
              {
                initialValue: '',
                rules: [{ required: true, message: 'Pilih layanan pasien!' }],
              },
            ]),expression:"[\n              'layanan',\n              {\n                initialValue: '',\n                rules: [{ required: true, message: 'Pilih layanan pasien!' }],\n              },\n            ]"}],attrs:{"size":"default","placeholder":"Pilih Layanan","options":_vm.filteredSpecializations,"show-search":"","filter-option":false},on:{"search":_vm.handleSearch,"change":_vm.handleLayananChange}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v(" Dokter"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'attending_doctor_id',
              {
                initialValue: '',
                rules: [{ required: true, message: 'Pilih dokter!' }],
              },
            ]),expression:"[\n              'attending_doctor_id',\n              {\n                initialValue: '',\n                rules: [{ required: true, message: 'Pilih dokter!' }],\n              },\n            ]"}],attrs:{"size":"default","placeholder":"Pilih Dokter","options":_vm.filteredDoctorList,"show-search":"","filter-option":false,"disabled":!_vm.layanan || !_vm.doctorList.length},on:{"search":_vm.handleSearchDoctor}})],1),_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v(" No Telepon Selular"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._v(" Mohon konfirmasi nomor telepon selular terbaru ke pasien "),_c('div',{staticClass:"d-flex"},[_c('a-form-item',{staticClass:"col-xl-2"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone_no_code',
                {
                  initialValue: this.phone_no_code,
                  rules: [
                    { required: true, message: 'Kode negara harus dipilih' },
                  ],
                },
              ]),expression:"[\n                'phone_no_code',\n                {\n                  initialValue: this.phone_no_code,\n                  rules: [\n                    { required: true, message: 'Kode negara harus dipilih' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Kode Negara"}},_vm._l((_vm.phoneCode),function(code){return _c('a-select-option',{key:code.country,attrs:{"value":code.code}},[_vm._v(" "+_vm._s(code.code)+" ")])}),1)],1),_c('a-form-item',{staticClass:"col-xl-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone_no',
                {
                  initialValue: this.phone_no,
                  rules: [
                    {
                      required: true,
                      message: 'Masukkan Nomor Telepon Pasien!',
                    },
                    {
                      pattern: /^\d{5,}$/,
                      message: 'Nomor telepon tidak valid',
                    },
                  ],
                },
              ]),expression:"[\n                'phone_no',\n                {\n                  initialValue: this.phone_no,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Masukkan Nomor Telepon Pasien!',\n                    },\n                    {\n                      pattern: /^\\d{5,}$/,\n                      message: 'Nomor telepon tidak valid',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"size":"default","placeholder":"0000000"}})],1)],1),_c('a-button',{staticStyle:{"float":"left"},attrs:{"htmlType":"submit","size":"large","type":"primary","shape":"round"},on:{"click":_vm.openModal}},[_c('img',{attrs:{"src":"resources/images/save-icon.svg","alt":"simpan","height":"25"}}),_c('strong',{staticClass:"h5"},[_vm._v(" Simpan")])])],1),_c('a-modal',{attrs:{"title":"Yakin isi formulir sudah sesuai?","ok-text":"Ya","cancel-text":"Kembali","centered":""},on:{"ok":_vm.handleSubmit,"cancel":_vm.closeModal},model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}},[_c('p',[_vm._v("Mohon cek kembali data kunjungan sebelum menyimpan formulir.")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }