<template>
  <div>
    <div class="floating-loading" v-if="getDataLoading">
      <div
        class="d-flex"
        style="
          border: 1px solid #e8e9eb;
          width: 120px;
          padding: 10px;
          border-radius: 10px;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 20px"
            class="mr-3 mb-0"
            spin
          />
        </a-spin>
        Loading...
      </div>
    </div>
    <div class="card custom-card">
      <div class="card-body w-100">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Formulir Registrasi Kunjungan
        </div>
        <a-form :form="form">
          <a-form-item>
            <div class="h6 font-weight-bold">Nama Lengkap</div>
            <a-input
              disabled
              size="default"
              placeholder="Masukkan nama lengkap"
              v-decorator="[
                'name',
                {
                  initialValue: this.name,
                  rules: [
                    {
                      required: true,
                      message: 'Masukkan Nama Lengkap Pasien!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item>
            <div class="h6 font-weight-bold">Tanggal Lahir</div>
            <a-input
              disabled
              size="default"
              placeholder="Masukkan tanggal lahir"
              v-decorator="[
                'birthdate',
                {
                  initialValue: this.birthdate,
                  rules: [
                    {
                      required: true,
                      message: 'Masukkan tanggal lahir Pasien!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item>
            <div class="h6 font-weight-bold">
              Jenis Kunjungan<span class="text-danger">*</span>
            </div>
            <a-input
              disabled
              size="default"
              placeholder="Masukkan jenis kunjungan"
              v-decorator="[
                'visit_type',
                {
                  initialValue: 'Rawat Jalan',
                  rules: [
                    {
                      required: true,
                      message: 'Masukkan Jenis Kunjungan Pasien!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item>
            <div class="h6 font-weight-bold">
              Layanan<span class="text-danger">*</span>
            </div>
            <a-select
              size="default"
              placeholder="Pilih Layanan"
              :options="filteredSpecializations"
              show-search
              :filter-option="false"
              @search="handleSearch"
              @change="handleLayananChange"
              v-decorator="[
                'layanan',
                {
                  initialValue: '',
                  rules: [{ required: true, message: 'Pilih layanan pasien!' }],
                },
              ]"
            />
          </a-form-item>

          <a-form-item>
            <div class="h6 font-weight-bold">
              Dokter<span class="text-danger">*</span>
            </div>
            <a-select
              size="default"
              placeholder="Pilih Dokter"
              :options="filteredDoctorList"
              show-search
              :filter-option="false"
              @search="handleSearchDoctor"
              v-decorator="[
                'attending_doctor_id',
                {
                  initialValue: '',
                  rules: [{ required: true, message: 'Pilih dokter!' }],
                },
              ]"
              :disabled="!layanan || !doctorList.length"
            />
          </a-form-item>

          <div class="h6 font-weight-bold">
            No Telepon Selular<span class="text-danger">*</span>
          </div>
          Mohon konfirmasi nomor telepon selular terbaru ke pasien
          <div class="d-flex">
            <a-form-item class="col-xl-2">
              <a-select
                v-decorator="[
                  'phone_no_code',
                  {
                    initialValue: this.phone_no_code,
                    rules: [
                      { required: true, message: 'Kode negara harus dipilih' },
                    ],
                  },
                ]"
                placeholder="Kode Negara"
                style="width: 100%"
              >
                <!-- <a-select-option value="+62">+62</a-select-option> -->
                <a-select-option
                  v-for="code in phoneCode"
                  :key="code.country"
                  :value="code.code"
                >
                  {{ code.code }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="col-xl-10">
              <a-input
                size="default"
                placeholder="0000000"
                v-decorator="[
                  'phone_no',
                  {
                    initialValue: this.phone_no,
                    rules: [
                      {
                        required: true,
                        message: 'Masukkan Nomor Telepon Pasien!',
                      },
                      {
                        pattern: /^\d{5,}$/,
                        message: 'Nomor telepon tidak valid',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>

          <a-button
            htmlType="submit"
            size="large"
            type="primary"
            shape="round"
            style="float: left"
            @click="openModal"
          >
            <img
              src="resources/images/save-icon.svg"
              alt="simpan"
              height="25"
            />
            <strong class="h5"> Simpan</strong>
          </a-button>
        </a-form>

        <a-modal
          v-model="isModalVisible"
          title="Yakin isi formulir sudah sesuai?"
          @ok="handleSubmit"
          @cancel="closeModal"
          ok-text="Ya"
          cancel-text="Kembali"
          centered
        >
          <p>Mohon cek kembali data kunjungan sebelum menyimpan formulir.</p>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllSpecializations,
  getDoctorBySpecialization,
} from "@/services/axios/api/dokter"
import { getById, editById } from "@/services/axios/api/pasien"
import { create } from "@/services/axios/api/kunjungan"
import { getPhoneCode } from "@/services/axios/api/phoneCode"

export default {
  data: function () {
    return {
      filteredSpecializations: [],
      specializations: [],
      form: this.$form.createForm(this),
      layanan: "",
      layananList: "",
      doctorList: [],
      filteredDoctorList: [],
      name: "",
      birthdate: "",
      phone_no: "",
      phone_no_code: "+62",
      countryPhoneCodes: [
        "62",
        "60",
        "63",
        "65",
        "66",
        "670",
        "673",
        "675",
        "84",
        "855",
        "856",
        "95",
        "1",
        "44",
        "33",
      ],
      phoneCode: [],
      isLoading: false,
      getDataLoading: false,
      isModalVisible: false,
    }
  },
  created() {
    this.patient_id = this.$route.params.id
  },
  async mounted() {
    this.getDataLoading = true
    await this.getPhoneCode()
    await this.getPasienData()
    await this.getLayananList()
    await this.getDoctorList()
    this.getDataLoading = false
  },
  methods: {
    handleSearch(value) {
      this.filteredSpecializations = this.specializations.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase()),
      )
      this.filteredSpecializations.unshift({
        value: "",
        label: "Pilih Layanan",
      })
    },
    handleSearchDoctor(value) {
      this.filteredDoctorList = this.doctorList.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase()),
      )
      this.filteredDoctorList.unshift({
        value: "",
        label: "Pilih Dokter",
      })
    },
    handleSubmit(e) {
      this.isLoading = true
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
          if (values.phone_no && values.phone_no.startsWith("0")) {
            values.phone_no = values.phone_no.replace(/^0+/, "")
          }
          values.phone_no = values.phone_no_code + values.phone_no
          delete values.phone_no_code
          const payloadTambahKunjungan = {
            patient_id: this.patient_id,
            attending_doctor_id: values.attending_doctor_id,
            visit_type: values.visit_type,
          }
          const payloadUpdateNoTelp = {
            phone_no: values.phone_no,
          }

          const responseTambahKunjungan = await create(payloadTambahKunjungan)
          // console.log(responseTambahKunjungan, 'responseTambahKunjungan');
          if (responseTambahKunjungan.status === 201) {
            // console.log('Berhasil menambahkan kunjungan');
            const responseUpdateNoTelp = await editById(
              this.patient_id,
              payloadUpdateNoTelp,
            )
            // console.log(responseUpdateNoTelp, 'responseUpdateNoTelp');
            if (responseUpdateNoTelp.status === 200) {
              // console.log('Berhasil mengupdate nomor telepon');
              this.$notification.success({
                message: "Berhasil",
                description:
                  "Berhasil menambahkan kunjungan dan mengupdate nomor telepon",
                duration: 5,
              })
              this.isLoading = false
              this.$router.push(`/pasien`)
            }
          }
        }
        this.isLoading = false
      })
    },
    openModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    async getPasienData() {
      try {
        const response = await getById(this.patient_id)
        // console.log(response, 'pasienData');
        this.name = response.patient.name
        this.birthdate = response.patient.birth_date
        // this.phone_no = response.patient.phone_no;
        const phone = this.checkPhoneNumber(response.patient.phone_no)
        this.phone_no = phone.phone_no
        this.phone_no_code = phone.phone_no_code
      } catch (error) {
        console.log(error)
      }
    },
    checkPhoneNumber(phoneNo) {
      for (let i = 1; i <= 7; i++) {
        const possibleCode = phoneNo.substring(0, i)
        if (this.countryPhoneCodes.includes(possibleCode)) {
          // this.phone_no = phoneNo.substring(1 + i);
          // this.phone_no_code = "+" + possibleCode;
          return {
            phone_no: phoneNo.substring(i),
            phone_no_code: possibleCode,
          }
        }
      }
      this.$notification.error({
        message: "No. telepon pada database tidak valid!",
        description: "Silahkan mengisi ulang nomor telepon pasien",
        duration: 5,
      })
    },
    async getLayananList() {
      try {
        const response = await getAllSpecializations()
        this.specializations = response.specializations.map((e) => {
          return {
            value: e.id,
            label: e.name,
          }
        })
        this.filteredSpecializations = response.specializations.map((e) => {
          return {
            value: e.id,
            label: e.name,
          }
        })

        this.filteredSpecializations.unshift({
          value: "",
          label: "Pilih Layanan",
        })
        this.layananList = response.specializations
        // console.log(response, 'layananList');
      } catch (error) {
        console.log(error)
      }
    },
    handleLayananChange(value) {
      this.layanan = value
      this.getDoctorList()
    },
    async getDoctorList() {
      try {
        const response = await getDoctorBySpecialization(this.layanan)
        // console.log(response, "cekinirespon")
        this.doctorList = response.doctor.map((e) => {
          return {
            value: e.id,
            label: e.User.name,
          }
        })
        this.filteredDoctorList = response.doctor.map((e) => {
          return {
            value: e.id,
            label: e.User.name,
          }
        })
        this.filteredDoctorList.unshift({
          value: "",
          label: "Pilih Dokter",
        })
        // console.log(this.filteredDoctorList, "cekinigazi")
        // console.log(response, 'doctorList');
      } catch (error) {
        console.log(error)
      }
    },
    async getPhoneCode() {
      try {
        const response = await getPhoneCode()
        this.phoneCode = response
        // console.log(this.phoneCode);
        const getCountryCodes = this.phoneCode.map((item) => item.code)
        this.countryPhoneCodes = getCountryCodes
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}

.custom-card {
  max-width: 680px;
  margin: 0 auto;
}

.custom-dropdown {
  width: 100%;
  margin-top: 0;
}

.custom-dropdown-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.one-button-container {
  display: flex;
  justify-content: flex-end;
}
.two-button-container {
  display: flex;
  justify-content: space-between;
}
.button {
  border-radius: 20px;
  height: 40px;
  width: 120px;
  border: none;
  font-size: 16px;
}

.next-button {
  background-color: #428a94;
  color: white;
  float: right;
}
.next-button:hover {
  background-color: #2c6e77;
}
.next-button:active {
  background-color: #1f4f57;
}

.prev-button {
  background-color: white;
  color: #428a94;
  border: 1px solid #428a94;
}
.prev-button:hover {
  background-color: #f5f5f5;
}
.prev-button:active {
  background-color: #e5e5e5;
}
.floating-loading {
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* for mobile */
  @media (max-width: 768px) {
    top: 40px;
  }
}
</style>
